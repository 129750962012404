import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
        display: 'inline-block',
    },
}));

const LoadingButton = ({ isLoading, children, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button {...props} disabled={isLoading}>
                {children}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};

export default LoadingButton;
