import "./App.css";
import Routes from "./Routes";
import config from "./config";
import {Auth} from "aws-amplify";
import {withRouter} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import React, {useEffect, useState} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {Nav, Navbar, Form} from "react-bootstrap";

function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [userAttributes, setUserAttributes] = useState({});
    const [username, setUsername] = useState('');

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);

            const response = await Auth.currentUserInfo();
            setUserAttributes(response.attributes);
            setUsername(response.username);
        } catch (e) {
            alert("No se pudieron obtener sus credenciales, por favor, autentifíquese de nuevo");
        }
        setIsAuthenticating(false);
    }

    useEffect(() => {
        onLoad();
    }, []);

    async function handleLogout() {
        await Auth.signOut();

        userHasAuthenticated(false);
        props.history.push("/login");
    }

    return (
        !isAuthenticating &&
        <div className="App container">
            <Navbar bg="translucent" expand="lg">
                <Navbar.Brand onClick={() => props.history.push("/")}>
                    <Tooltip title={config.env}>
                        <span>Portal Tesorería</span>
                    </Tooltip>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {isAuthenticated
                            ? <>
                                <Nav.Link onClick={() => props.history.push("/")}>Estudiantes</Nav.Link>
                                <Nav.Link onClick={() => props.history.push("/professors")}>Profesores</Nav.Link>
                                <Nav.Link onClick={() => props.history.push("/summary")}>Corte</Nav.Link>
                                <Nav.Link onClick={() => props.history.push("/month")}>Mes</Nav.Link>
                            </>
                            : <></>
                        }
                    </Nav>
                    <Form inline>
                        <Navbar.Collapse>
                            <Nav className="ml-auto">
                                {isAuthenticated
                                    ? <Nav.Link onClick={handleLogout}>Cerrar Sesión</Nav.Link>
                                    : <>
                                        <LinkContainer to="/signup">
                                            <Nav.Link>Crear cuenta</Nav.Link>
                                        </LinkContainer>
                                        <LinkContainer to="/login">
                                            <Nav.Link>Entrar</Nav.Link>
                                        </LinkContainer>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
            <Routes appProps={{isAuthenticated, userHasAuthenticated, userAttributes, username}}/>
        </div>
    );
}

export default withRouter(App);
