import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS} from "../../Material-TableCustomization/ColumnsDefinition/StudentsPaymentsColumnDefinition";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import MaterialTable from "material-table";
import {STUDENT_SCHOLARSHIPS_COLUMN_DEFINITION_PROPS} from "../../Material-TableCustomization/ColumnsDefinition/StudentScholarshipsColumnDefinitions";

export default function StudentScholarshipsTable({scholarships}) {
    return (
        <>
            <MaterialTable
                    columns={STUDENT_SCHOLARSHIPS_COLUMN_DEFINITION_PROPS()}
                    data={scholarships}
                    title="Becas"
                    isLoading={scholarships}
                    options={{
                        pageSize: 10,
                        addRowPosition: 'first',
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('beca', 'becas')}
                />
        </>
    );
};