import {API} from "aws-amplify";
import '../Stylesheets/Professors.css'
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {
    PROFESSORS_COLUMN_DEFINITION_PROPS
} from "../../components/Material-TableCustomization/ColumnsDefinition/ProfessorsColumnDefinition";

export default function Professors(props) {
    const [professors, setProfessors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadProfessorsApi = () => {
        return API.get('misServiciosEscolares', "/professors");
    };

    const loadProfessors = async () => {
        setIsLoading(true);
        try {
            const response = await loadProfessorsApi();
            setProfessors(response);
        } catch (e) {
            alert("No se pudo obtener la lista de profesores. Por favor intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangeOnAlert = (shouldPresentAlert) => {
        setAlertStatus(shouldPresentAlert);
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadProfessors();
    }, [props.isAuthenticated]);

    const renderTeachers = () => {
        return (
            <div className="Students">
                <MaterialTable
                    columns={PROFESSORS_COLUMN_DEFINITION_PROPS(props)}
                    data={professors}
                    title="Profesores UVT"
                    isLoading={isLoading}
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('profesor', 'profesores')}
                    options={{
                        search: true,
                        pageSize: 10,
                        filtering: true,
                    }}
                />
            </div>
        );
    }

    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderTeachers() : renderLander()}
        </div>
    );
};