import React from "react";
import {
    PAYMENTS_CONCEPTS,
} from "../../CommonUtilities/UtilityFunctions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function PAYMENTS_SUMMARY_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: "Nombre",
            field: "name",
            render: rowData =>
                <Button color='secondary'
                        onClick={() => props.history.push(`/students/${rowData.studentUuid}`)}
                        component={Link}>
                    {rowData.studentUuid === "TESORERIA"
                        ? "TESORERIA"
                        : `${rowData.name}`
                    }
                </Button>
        },
        {
            title: "Recibido",
            field: "moneyTotal",
            type: "currency"
        },
        {
            title: "Grado",
            // field: "scholarYear",
            render: rowData => <>{rowData.scholarYear}-{rowData.major}</>
        },
        {
            title: "Concepto",
            field: "concept",
            lookup: PAYMENTS_CONCEPTS,
        },
        {
            title: "Mes",
            field: "monthConcept",
            lookup: {
                0: "Enero",
                1: "Febrero",
                2: "Marzo",
                3: "Abril",
                4: "Mayo",
                5: "Junio",
                6: "Julio",
                7: "Agosto",
                8: "Septiembre",
                9: "Octubre",
                10: "Noviembre",
                11: "Diciembre",
            }
        },
        {
            title: "Año",
            field: "yearConcept",
        },
        {
            title: "Referencia",
            field: "reference"
        },
        {
            title: "Tipo de Pago",
            field: "paymentType"
        },
        {
            title: "Recibido",
            field: "moneyTotal",
            type: "currency"
        },
    ];
}
