import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PaymentsSummaryTableInfoCard from "./PaymentsSummaryTableInfoCard";
import Typography from "@material-ui/core/Typography";
import {getMonthString} from "../../CommonUtilities/UtilityFunctions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {indigo} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    select: {
        marginLeft: theme.spacing(2),
        fontSize: '2.6rem',
        fontColor: 'blue',
        color: indigo[500]
    },
    menuItem: {
        fontSize: '1rem', // Adjust font size here
    },
}));

export default function PaymentsSummaryTableMetadata({incomeOther, incomeCash, outcomeGastos, outcomeCortes, chosenMonth, setChosenMonth}) {
    const classes = useStyles();
    const MSE_PAYMENTS_SUMMARY_METADATA = [
        {title: "Ingresos Otros", subtitle1: "Bancos & transferencias", type: "NORMAL", value: incomeOther},
        {title: "Ingresos Efectivo", subtitle1: "Dinero recibido en caja", type: "NORMAL", value: incomeCash},
        {title: "Egresos Gastos", subtitle1: "Gastos diarios en efectivo", type: "ALERT", value: outcomeGastos},
        {title: "Cortes de Caja", subtitle1: "Gastos diarios en efectivo", type: "ALERT", value: outcomeCortes},
    ];

    const handleMonthChange = (event) => {
        setChosenMonth(getMonthString(event.target.value));
    };

    const months = Array.from({ length: new Date().getMonth() + 1 }, (_, i) => i);

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h3">
                    Resumen {" "}
                </Typography>
                <Select
                    value={chosenMonth?.value}
                    onChange={handleMonthChange}
                    className={classes.select}
                >
                    {months.map((month) => (
                        <MenuItem key={month} value={month} className={classes.menuItem}>
                            {getMonthString(month).label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Grid container spacing={3}>
                {MSE_PAYMENTS_SUMMARY_METADATA.map(o => (
                    <Grid item xs>
                        <PaymentsSummaryTableInfoCard subtitle1={o.subtitle1} title={o.title} value={o.value}
                                                      type={o.type}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
