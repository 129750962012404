import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function StudentGradesTable(props) {
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Materia</TableCell>
                        <TableCell>Cuatrimestre</TableCell>
                        <TableCell>Calificación</TableCell>
                        <TableCell>Extraordinario</TableCell>
                        <TableCell>Equivalencia</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {props.grades
                        ? props.grades.map((row) => (
                            <TableRow key={row.studentId} style={{backgroundColor: row.failed ? "#ffcdd2" : "white"}}>
                                <TableCell component="th" scope="row"> {row.topicId} </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.cuatrimestre}</TableCell>
                                <TableCell>{row.grade}</TableCell>
                                <TableCell>{row.extraordinario}</TableCell>
                                <TableCell>{row.equivalencia}</TableCell>
                            </TableRow>
                        ))
                        : <></>}
                </TableBody>
            </Table>
        </TableContainer>
    );
};