import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardActionArea, CardContent, Paper} from "@material-ui/core";
import {formatNumberCurrency} from "../../CommonUtilities/UtilityFunctions";
import {red} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: "100px",
    },
    pos: {
        marginBottom: 12,
    },
}));

export default function PaymentsSummaryTableInfoCard({title, subtitle1, value, type}) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {subtitle1}
                    </Typography>
                    <Typography variant="h4" component="h2"
                                style={{color: type === "ALERT" ? red[500] : "#959595"}}
                    >
                        {formatNumberCurrency(value)}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};