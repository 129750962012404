import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import StyledContentLoader from 'styled-content-loader'
import {TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {API} from "aws-amplify";
import {API_BASE_PATH} from "../../CommonUtilities/UtilityFunctions";

export const useStyles = makeStyles((theme) => ({
    failedStudents: {
        backgroundColor: red[100]
    }
}));

export default function StudentTableMetadata({studentDetails}) {
    const classes = useStyles();
    const [notesTesoreria, setNotesTesoreria] = React.useState("");

    function saveNotesApi() {
        return API.put(API_BASE_PATH, `/students`, {
            body: {studentUuid: studentDetails.studentUuid, notesTesoreria: notesTesoreria}
        });
    }

    async function saveNotes() {
        if (!studentDetails.studentUuid) {
            alert("No se pueden gardar las notas");
            return;
        }
        try {
            await saveNotesApi();
        } catch (e) {
            console.log(e.message);
            alert("No se pudo guardar las notas de tesorería, por favor intente de nuevo");
        }
    }

    useEffect(() => {
        setNotesTesoreria(studentDetails.notesTesoreria);
    }, [studentDetails])

    // Extract TextField and add it as a component
    return (
        <div>
            {studentDetails ?
                <div>
                    <StyledContentLoader isLoading={studentDetails.studentId ? false : true}>
                        <Typography variant="h4" component="h2">
                            ESTUDIANTE: {studentDetails?.name ? studentDetails.name : ""}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {studentDetails.studentId} {studentDetails.major} {studentDetails.cuatrimestre}{studentDetails.group}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            CURP: {studentDetails.curp}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            PROMEDIO: {studentDetails.average}
                        </Typography>
                        <Typography variant="h5" component="h2" className={classes.failedStudents}>
                            Materias Reprobadas: {studentDetails.numberOfFailedTopics}
                        </Typography>
                        <TextField label="Notas Tesorería"
                                   style={{width: '85%'}}
                                   value={notesTesoreria}
                                   onChange={e => setNotesTesoreria(e.target.value)}
                        />
                        <Button
                            // variant="contained"
                            color="primary"
                            // className={buttonClassname}
                            onClick={saveNotes}
                        >
                            Guardar Notas
                        </Button>

                    </StyledContentLoader>
                </div>
                : <></>}
        </div>
    );
}