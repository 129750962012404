import {API} from "aws-amplify";
import "../Stylesheets/StudentDetails.css";
import React, {useState, useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import StudentTableMetadata from "../../components/Details/Student/StudentTableMetadata";
import StudentDetailsTabsContainer from "../../components/Details/Student/StudentDetailsTabsContainer";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {API_BASE_PATH} from "../../components/CommonUtilities/UtilityFunctions";
import ModalNewPayment from "../../components/Modals/ModalNewPayment";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function StudentDetails(props) {
    const [studentDetails, setStudentDetails] = useState([]);
    const [studentGrades, setStudentGrades] = useState([]);
    const classes = useStyles();

    function loadStudentDetails() {
        return API.get(API_BASE_PATH, `/students/${props.match.params.id}?includeScholarships=true`);
    }

    function loadStudentGrades() {
        return API.get("misServiciosEscolares", `/grades/${props.match.params.id}?fullDetails=true`);
    }

    useEffect(() => {
        async function onLoad() {
            try {
                const studentDetailsResponse = await loadStudentDetails();
                setStudentDetails(studentDetailsResponse);
                // Changed and now the response is an object and not an array of grades
                const studentGradesResponse = await loadStudentGrades();
                setStudentGrades(studentGradesResponse.grades);
                setStudentDetails({
                    ...studentDetailsResponse,
                    average: studentGradesResponse.average,
                    numberOfFailedTopics: studentGradesResponse.numberOfFailedTopics
                });
            } catch (e) {
                console.log(e);
                alert("No se encontraron los detalles del alumno seleccionado. Por favor intente de nuevo");
            }
        }
        onLoad();
    }, [props.match.params.id]);

    function renderGroup() {
        return (
            <div>
                {props.location.search}
                <div className={classes.title}>
                    <StudentTableMetadata studentDetails={studentDetails}/>
                </div>
                <div>
                    <StudentDetailsTabsContainer
                        student={studentDetails}
                        grades={studentGrades}
                        studentUuid={props.match.params.id}
                    />
                </div>
            </div>
        );
    }
    return (
        <div>
            {props.isAuthenticated ? renderGroup() : renderLander()}
        </div>
    );
};