import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PaymentsSummaryTableInfoCard from "./PaymentsSummaryTableInfoCard";
import Typography from "@material-ui/core/Typography";
import {formatNumberCurrency} from "../../CommonUtilities/UtilityFunctions";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function PaymentsSummaryTableMetadataDaily({incomeOther, incomeCash, caja, outcomeGastos, outcomeCortes, summaryDate}) {
    const classes = useStyles();
    const MSE_PAYMENTS_SUMMARY_METADATA = [
        {title: "Ingresos Otros", subtitle1: "Bancos & transferencias", type: "NORMAL", value: incomeOther},
        {title: "Ingresos Efectivo", subtitle1: "Dinero recibido en caja", type: "NORMAL", value: incomeCash},
        {title: "Egresos Gastos", subtitle1: "Gastos diarios en efectivo", type: "ALERT", value: outcomeGastos},
        {title: "Cortes de Caja", subtitle1: "Gastos diarios en efectivo", type: "ALERT", value: outcomeCortes},
    ]

    return (
        <div className={classes.root}>
            <Typography variant="h3">
                Corte del día {summaryDate}
            </Typography>
            <Typography variant="h4">
                Disponible en caja {formatNumberCurrency(caja)}
            </Typography>
            <Grid container spacing={3}>
                {MSE_PAYMENTS_SUMMARY_METADATA.map(o => (
                    <Grid item xs>
                        <PaymentsSummaryTableInfoCard subtitle1={o.subtitle1} title={o.title} value={o.value} type={o.type}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
