import React from "react";
import {
    PAYMENTS_CONCEPTS,
    PAYMENTS_CONCEPTS_PRICES,
    PAYMENTS_DISCOUNTS, PAYMENTS_DISCOUNTS_VALUE,
    PAYMENTS_MONEY_TYPES,
} from "../../CommonUtilities/UtilityFunctions";
import {MTableEditField} from "material-table";

export function STUDENT_SCHOLARSHIPS_COLUMN_DEFINITION_PROPS() {
        return [
        {
            title: "Tipo de beca",
            field: "name",
            lookup: SCHOLARSHIPS

        },
        {
            title: "Fecha de inicio",
            field: "startDate",
            type: "date"
        },
        {
            title: "Fecha de finalización",
            field: "endDate",
            type: "date"
        },
    ];
}

export const SCHOLARSHIPS = {
    "CEDAR_100": "CEDAR_100",
    "EF_380": "EF_380",
    "EF_480": "EF_480",
    "SETAB_50": "SETAB_50",
    "SITEM_10": "SITEM_10",
}
