import React, {useState} from "react";
import {Auth} from "aws-amplify";
import "../Stylesheets/Signup.css";
import {useFormFields} from "../../libs/hooksLib";
import {amplifyConfig} from "../../AmplifyConfig";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Error';
import {green, red} from '@material-ui/core/colors';
import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">Mis Servicios Escolares</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Signup(props) {
    const classes = useStyles();
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: ""
    });
    const [newUser, setNewUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            Auth.configure(amplifyConfig);
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password,
                attributes: {
                    email: fields.email,
                    profile: 'TESORERIA',
                    name: `${fields.lastname} ${fields.firstname}`
                }
            });
            console.log(newUser, 'newUser');
            setNewUser(newUser);
        } catch (e) {
            console.log(e);
            if (e.code === 'UsernameExistsException') {
                alert('Ya existe una cuenta con ese email.');
            } else {
                alert('Por favor intenta de nuevo');
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);
            props.userHasAuthenticated(true);
            props.history.push("/");
        } catch (e) {
            console.log(e, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    function validateForm() {
        return emailIsNotEmpty() && hasLowerCase() && hasUpperCase() && has8Characters() && passwordsMatch() && fields.firstname && fields.lastname;
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    function emailIsNotEmpty() {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(fields.email).toLowerCase());
    }

    function has8Characters() {
        return fields.password.length > 7;
    }

    function passwordsMatch() {
        return fields.password === fields.confirmPassword;
    }

    function hasLowerCase() {
        return (/[a-z]/.test(fields.password));
    }

    function hasUpperCase() {
        return (/[A-Z]/.test(fields.password));
    }

    function renderConfirmationForm() {
        return (
            <>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="confirmationCode"
                        label="Ingresa tu Código de Confirmación"
                        autoFocus
                        value={fields.confirmationCode}
                        onChange={handleFieldChange}
                    />
                    <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleConfirmationSubmit}
                        disabled={!validateConfirmationForm()}
                    >
                        Confirmar Cuenta
                    </Button>
                </form>
            </>
        );
    }

    function renderSignUpFullForm() {
        return (
            <>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstname"
                        label="Nombre(s)"
                        name="firstname"
                        autoFocus
                        value={fields.firstname}
                        onChange={handleFieldChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lastname"
                        label="Apellidos"
                        name="lastname"
                        autoFocus
                        value={fields.lastname}
                        onChange={handleFieldChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Dirección Email"
                        name="email"
                        autoComplete="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={fields.password}

                        onChange={handleFieldChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Ingrese Password de Nuevo"
                        type="password"
                        id="confirmPassword"
                        autoComplete="current-password"
                        onChange={handleFieldChange}
                        value={fields.confirmPassword}
                    />
                    <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={!validateForm()}
                    >
                        Siguiente Paso (No cierre esta ventana)
                    </Button>
                </form>
                < div className="CreateAccountHelper" style={{margin: '0 auto', textAlign: 'left', color: '#333333'}}>
                    <div>
                        {emailIsNotEmpty() && <CheckCircleIcon style={{fontSize: "small", color: green[500]}}/>}
                        {!emailIsNotEmpty() && <CancelIcon style={{fontSize: "small", color: red[500]}}/>}
                        <span style={{paddingLeft: '5px'}}>El email debe de tener un formato válido</span>
                    </div>
                    <div>
                        {passwordsMatch() && <CheckCircleIcon style={{fontSize: "small", color: green[500]}}/>}
                        {!passwordsMatch() && <CancelIcon style={{fontSize: "small", color: red[500]}}/>}
                        <span style={{paddingLeft: '5px'}}>Tus contraseñas deben de coincidir</span>
                    </div>
                    <div>
                        {has8Characters() && <CheckCircleIcon style={{fontSize: "small", color: green[500]}}/>}
                        {!has8Characters() && <CancelIcon style={{fontSize: "small", color: red[500]}}/>}
                        <span
                            style={{paddingLeft: '5px'}}>Tu contraseña debe de tener por lo menos 8 caracteres</span>
                    </div>
                    <div>
                        {hasUpperCase() && <CheckCircleIcon style={{fontSize: "small", color: green[500]}}/>}
                        {!hasUpperCase() && <CancelIcon style={{fontSize: "small", color: red[500]}}/>}
                        <span
                            style={{paddingLeft: '5px'}}>Tu contraseña debe de tener por lo menos 1 mayúscula</span>
                    </div>
                    <div>
                        {hasLowerCase() && <CheckCircleIcon style={{fontSize: "small", color: green[500]}}/>}
                        {!hasLowerCase() && <CancelIcon style={{fontSize: "small", color: red[500]}}/>}
                        <span
                            style={{paddingLeft: '5px'}}>Tu contraseña debe de tener por lo menos 1 minúscula</span>

                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="Signup">
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{color: '#333333'}}>
                        Crea tu Cuenta
                    </Typography>
                    {newUser === null ? renderSignUpFullForm() : renderConfirmationForm()}
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </Container>
        </div>
    );
};
