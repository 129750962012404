import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import PaymentsSummaryConceptCard from "./PaymentsSummaryConceptCard";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    listItem: {
        width: '100%'
    }
}));

export default function PaymentsSummaryConcept({dayPaymentsMetadata}) {
    const classes = useStyles();
    const [conceptSummary, setConceptSummary] = useState({});

    useEffect(() => {
        const {
            titulacion,
            practicasEnfermeria,
            cedarUniformes,
            preparatoriaLibros,
            secundariaLibros,
            titulacionTesis,
            titulacionOtras,
            titulacionCurso,
            titulaciónDiplomado,
            cedula,
            librosSecundaria,
            librosPreparatoria,
            uvtSeguroEscolar,
            uvtSeguroEnfermeria
        } = dayPaymentsMetadata;

        setConceptSummary({
            titulacion,
            practicasEnfermeria,
            cedarUniformes,
            preparatoriaLibros,
            secundariaLibros,
            titulacionTesis,
            titulacionOtras,
            titulacionCurso,
            titulaciónDiplomado,
            cedula,
            librosSecundaria,
            librosPreparatoria,
            uvtSeguroEscolar,
            uvtSeguroEnfermeria,
        });
    }, [dayPaymentsMetadata]);

    return (
        <div>
            <Grid container spacing={3} className={classes.root}>
                {Object.keys(conceptSummary).map(o => {
                    return (
                        <Grid item xs={4}>
                            <PaymentsSummaryConceptCard summary={o} conceptSummary={conceptSummary}/>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};