import {API} from "aws-amplify";
import "../Stylesheets/StudentDetails.css";
import React, {useState, useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {
    API_BASE_PATH,
    getMonthString,
    getTodayDateYYY_MM_DD
} from "../../components/CommonUtilities/UtilityFunctions";
import PaymentsSummaryTableMetadata from "../../components/Details/Payments/PaymentsSummaryTableMetadata";
import * as XLSX from "xlsx";
import LoadingButton from "../../components/LoadingButton";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function SummaryMonth(props) {
    const [dayPayments, setDayPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [chosenMonth, setChosenMonth] = useState(getMonthString());
    const [dayPaymentsMetadata, setDayPaymentsMetadata] = useState({
        profit: "",
        incomeTotal: "",
        incomeOther: "",
        incomeCash: "",
        outcomeCash: "",
        caja: "",
        titulacion: "",
        practicasEnfermeria: "",
        cedarUniformes: "",
        preparatoriaLibros: "",
        secundariaLibros: "",
        uvtSeguroEnfermeria: "",
        uvtSeguroEscolar: "",
    });
    const classes = useStyles();

    function loadDatePaymentsApi() {
        return API.get(API_BASE_PATH, `/tesoreria/list-summary-payments?month=${chosenMonth?.value}`);
    }

    async function loadDatePayments() {
        setIsLoading(true);
        try {
            const response = await loadDatePaymentsApi();
            setDayPayments(response.dayPayments);
            const {
                profit,
                incomeTotal,
                incomeOther,
                incomeCash,
                outcomeCash,
                outcomeGastos,
                outcomeCortes,
                caja,
                titulacion,
                practicasEnfermeria,
                cedarUniformes,
                preparatoriaLibros,
                secundariaLibros,
                titulacionTesis,
                titulacionOtras,
                titulacionCurso,
                titulaciónDiplomado,
                uvtSeguroEnfermeria,
                uvtSeguroEscolar,
            } = response;
            setDayPaymentsMetadata({
                profit,
                incomeTotal,
                incomeOther,
                incomeCash,
                outcomeCash,
                outcomeGastos,
                outcomeCortes,
                caja,
                titulacion,
                practicasEnfermeria,
                cedarUniformes,
                preparatoriaLibros,
                secundariaLibros,
                titulacionTesis,
                titulacionOtras,
                titulacionCurso,
                titulaciónDiplomado,
                uvtSeguroEnfermeria,
                uvtSeguroEscolar,
            });
        } catch (e) {
            alert("No se pudieron obtener los pagos del día");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadDatePayments();
    }, [chosenMonth]);

    function handleOnExport() {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dayPayments.map(o => ({
            fecha: o.friendlyDate, metodoPago: o.paymentType, nombre: o.name, recibido: o.moneyTotal, concept: o.concept,
            mes: chosenMonth?.label, año: o.yearConcept, referencia: o.reference, grado: o.major
        })));
        XLSX.utils.book_append_sheet(wb, ws, "Pagos");
        XLSX.writeFile(wb, `${chosenMonth?.label}-2024.xlsx`);
    }

    function renderGroup() {
        return (
            <>
                <div className={classes.title}>
                    <PaymentsSummaryTableMetadata
                        profit={dayPaymentsMetadata?.profit}
                        incomeTotal={dayPaymentsMetadata?.incomeTotal}
                        incomeOther={dayPaymentsMetadata?.incomeOther}
                        incomeCash={dayPaymentsMetadata?.incomeCash}
                        outcomeCash={dayPaymentsMetadata?.outcomeCash}
                        caja={dayPaymentsMetadata?.caja}
                        outcomeCortes={dayPaymentsMetadata.outcomeCortes}
                        outcomeGastos={dayPaymentsMetadata.outcomeGastos}
                        chosenMonth={chosenMonth}
                        setChosenMonth={setChosenMonth}
                    />
                </div>
                <LoadingButton variant="contained" color="primary" onClick={handleOnExport} isLoading={isLoading}>
                    {isLoading ? "Cargando Información..." : "Exportar Detalles"}
                </LoadingButton>
            </>
        );
    }

    return (
        <div>
            {props.isAuthenticated ? renderGroup() : renderLander()}
        </div>
    );
};
