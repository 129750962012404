import "../Stylesheets/StudentDetails.css";
import React, {useEffect, useState} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import ProfessorDetailsTabsContainer from "../../components/Details/Professors/ProfessorDetailsTabsContainer";
import ProfessorTableMetadata from "../../components/Details/Professors/ProfessorTableMetadata";
import {API} from "aws-amplify";

export default function ProfessorDetails(props) {
    const [professorMetadata, setProfessorMetadata] = useState({});

    const loadProfessorMetadataApi = () => {
        return API.get("misServiciosEscolares", `/professors/${props.match.params.id}`);
    }

    const loadProfessorMetadata = async () => {
        try {
            const response = await loadProfessorMetadataApi();
            setProfessorMetadata(response);
        } catch (e) {
            alert("No se pudo obtener la información de este profesor, por favor intente más tarde");
        }
    }

    useEffect(() => {
        console.log(props.match.params.id, 'professorId');
        loadProfessorMetadata();
    }, [props.match.params.id]);

    function renderProfessorDetails() {
        return (
            <>
                <ProfessorTableMetadata
                    professorMetadata={professorMetadata}/>
                <ProfessorDetailsTabsContainer
                    professorId={props.match.params.id}
                    history={props.history}
                    professorMetadata={professorMetadata}/>
            </>
        );
    }

    return (
        <div className="StudentDetails">
            {props.isAuthenticated ? renderProfessorDetails() : renderLander()}
        </div>
    );
};