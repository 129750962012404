import React from "react";
import Typography from "@material-ui/core/Typography";

export const renderLander = () => {
    return (
        <div className="lander">
            <div>
                <Typography variant="h4" component="h2">
                    Mis Servicios Escolares
                </Typography>
            </div>
            < div>
                < Typography variant="h5" component="h2">
                    Portal Tesorería
                </Typography>
            </div>
        </div>
    );
};