import React from "react";
import {
    PAYMENTS_CONCEPTS,
    PAYMENTS_CONCEPTS_PRICES,
    PAYMENTS_DISCOUNTS, PAYMENTS_DISCOUNTS_VALUE,
    PAYMENTS_MONEY_TYPES,
} from "../../CommonUtilities/UtilityFunctions";
import {MTableEditField} from "material-table";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";

export function STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: "Concepto",
            field: "concept",
            lookup: PAYMENTS_CONCEPTS,
            editComponent: ({onChange, onRowDataChange, ...props}) => {
                const onCustomChange = value => {
                    let responseObject = {...props.rowData};
                    const price = PAYMENTS_CONCEPTS_PRICES[value] || 0;
                    const discount = PAYMENTS_DISCOUNTS_VALUE[responseObject.promo] || 0;
                    responseObject = {
                        ...responseObject,
                        concept: value,
                        price: PAYMENTS_CONCEPTS_PRICES[value],
                        discount: discount,
                        total: price - discount
                    };
                    onRowDataChange(responseObject);
                };
                return <MTableEditField onChange={onCustomChange} {...props} />;
            },
        },
        {
            title: "Fecha",
            field: "friendlyDate",
            type: "date",
        },
        {
            title: "Ref (opc)",
            field: "reference"
        },
        {
            title: "MES",
            field: "monthConcept",
            lookup: {
                "NO_APLICA": "NO_APLICA",
                0: "ENERO",
                1: "FEBRERO",
                2: "MARZO",
                3: "ABRIL",
                4: "MAYO",
                5: "JUNIO",
                6: "JULIO",
                7: "AGOSTO",
                8: "SEPTIEMBRE",
                9: "OCTUBRE",
                10: "NOVIEMBRE",
                11: "DICIEMBRE",
            }
        },
        {
            title: "Año",
            field: "yearConcept",
            lookup: {
                "2014": "2014",
                "2015": "2015",
                "2016": "2016",
                "2017": "2017",
                "2018": "2018",
                "2019": "2019",
                "2020": "2020",
                "2021": "2021",
                "2022": "2022",
                "2023": "2023",
                "2024": "2024",
                "2025": "2025",
                "2026": "2026",
            }
        },
        // {
        //     title: "Pago de",
        //     render: rowData =>
        //         <>{rowData.monthConcept < 9
        //             ? <>0{rowData.monthConcept+1}-{rowData.yearConcept}</>
        //             : <>{rowData.monthConcept+1}-{rowData.yearConcept}</>}
        //         </>,
        //
        // },
        // {
        //     title: "Promo",
        //     field: "promo",
        //     lookup: PAYMENTS_DISCOUNTS,
        //     editComponent: ({onChange, onRowDataChange, ...props}) => {
        //         const onCustomChange = value => {
        //             let responseObject = {...props.rowData};
        //             console.log(responseObject, 'responseObject');
        //             console.log(responseObject.promo, 'promo');
        //             const discount = PAYMENTS_DISCOUNTS_VALUE[value] || 0;
        //             responseObject = {
        //                 ...responseObject,
        //                 promo: value,
        //                 discount: discount,
        //                 total: props.rowData.price - discount
        //             };
        //             onRowDataChange(responseObject);
        //         };
        //         return <MTableEditField onChange={onCustomChange} {...props} />;
        //     }
        // },
        {
            title: "Recibido",
            field: "moneyTotal",
            type: "currency",
            editable: false
        },
        {
            title: "Precio",
            field: "price",
            type: "currency",
            editable: false
        },
        {
            title: "Descuento",
            field: "discount",
            type: "currency",
            editable: false,

        },
        {
            title: "Total",
            field: "total",
            type: "currency",
            editable: false
        }
    ];
}
