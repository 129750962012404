import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, {Fragment, useState} from "react";
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import {API} from "aws-amplify";
import {Tooltip} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {MSE_TESORERIA_YEAR_CONCEPT} from "./ModalNewPayment";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 550
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function ModalNewOutcomePayment({setIsLoading, onOutcomePaymentCreated}) {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [payment, setPayment] = useState({concept: "EGRESO_GASTO"});

    const createPaymentApi = () => {
        return API.post("misServiciosEscolares", "/tesoreria/payments", {
            body: {
                ...payment,
                moneyTotal: payment.moneyTotal ? `-${payment.moneyTotal}` : "0",
                studentUuid: "TESORERIA",
                paymentType: "EFECTIVO"
            }
        });
    };

    const handleSubmit = async () => {
        clearPaymentAndCloseModal()
        setIsLoading(true);
        try {
            const createdPayment = await createPaymentApi()
            onOutcomePaymentCreated(createdPayment);
        } catch (e) {
            alert("No se pudo generar el pago, por favor intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    };

    const clearPaymentAndCloseModal = () => {
        setOpenModal(false);
        setPayment({});
    };

    const MSE_TESORERIA_OUTCOME_CONCEPT = [
        {value: "EGRESO_CORTE", description: "Egreso Corte de Caja"},
        {value: "EGRESO_GASTO", description: "Egreso Gasto"},
    ]

    return (
        <div className="ModalNewStudent">
            <Fragment>
                <Dialog open={openModal} onClose={clearPaymentAndCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Crear Egreso</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.FormControl}>
                            <InputLabel id="mse-tesoreria-month-concept-input-label-id">
                                Selecciona tipo de transacción:
                            </InputLabel>
                            <Select
                                labelId="mse-tesoreria-concept-input-label-id"
                                id="mse-tesoreria-concept-input-label-id"
                                value={payment.concept}
                                defaultValue=""
                                onChange={e => setPayment({...payment, concept: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {MSE_TESORERIA_OUTCOME_CONCEPT.map(o => {
                                    return <MenuItem value={o.value}>{o.description}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            id="money-total"
                            label="Total Dinero Recibido"
                            type="number"
                            value={payment.moneyTotal || ""}
                            onChange={e => setPayment({...payment, moneyTotal: e.target.value})}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            id="reference"
                            label="Referencia"
                            multiline
                            rows={4}
                            value={payment.reference || ""}
                            onChange={e => setPayment({...payment, reference: e.target.value})}
                            className={classes.FormControl}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={clearPaymentAndCloseModal}
                            color="primary"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!(payment.reference && payment.concept)}
                        >
                            Crear Pago
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
            <Fab color="primary" aria-label="add" className={classes.fab}>
                <Tooltip title="Crear Egreso" aria-label="add">
                    <Fab color="primary" className={classes.fab}>
                        <AddIcon onClick={() => setOpenModal(true)}/>
                    </Fab>
                </Tooltip>
            </Fab>
        </div>
    );
};