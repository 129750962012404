import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {formatNumberCurrency} from "../../CommonUtilities/UtilityFunctions";
import ListItem from "@material-ui/core/ListItem";
import React from "react";

export default function PaymentsSummaryConceptCardItemList({conceptSummary, summary, item}) {
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    {item.icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={item.title}
                secondary={conceptSummary[summary]
                    ? conceptSummary[summary][item.key]
                        ? formatNumberCurrency(conceptSummary[summary][item.key])
                        : formatNumberCurrency(0)
                    : formatNumberCurrency(0)
                }/>
        </ListItem>
    );
}