import React from "react";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {Card, CardActionArea, CardContent, Typography} from "@material-ui/core";
import PaymentsSummaryConceptCardItemList from "./PaymentsSummaryConceptCardItemList";

const MSE_TITLES = {
    titulacion: "Titulación",
    practicasEnfermeria: "Prácticas Enfermería",
    cedarUniformes: "Uniformes CEDAR",
    preparatoriaLibros: "Libros de Preparatoria",
    secundariaLibros: "Libros de Secundaria",
    uvtColegiatura: "Colegiatura UVT",
    titulacionTesis: "Titulación por Tesis",
    titulacionOtras: "Titulación Otras",
    titulacionCurso: "Titulación Cursos",
    titulaciónDiplomado: "Titulación Diplomado",
    cedula: "Cédulas",
    librosSecundaria: "Libros Secundaria",
    librosPreparatoria: "Libros Preparatoria",
    uvtSeguroEscolar: "UVT Seguro Escolar",
    uvtSeguroEnfermeria: "UVT Seguro Enfermería",
};

const MSE_TITLE_DETAILS = [
    {title: "Efectivo", icon: <AttachMoneyIcon/>, key: "efectivo"},
    {title: "Tarjeta de Crédito/Débito", icon: <CreditCardIcon/>, key: "tarjeta"},
    {title: "Transferencia o Depósito", icon: <AccountBalanceIcon/>, key: "transferencia"},
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    listItem: {
        width: '100%'
    }
}));

export default function PaymentsSummaryConceptCard({summary, conceptSummary}) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {MSE_TITLES[summary] ?? ""}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <List className={classes.listItem}>
                            {MSE_TITLE_DETAILS.map(item => (
                                <PaymentsSummaryConceptCardItemList
                                    conceptSummary={conceptSummary}
                                    summary={summary}
                                    item={item}
                                />
                            ))}
                        </List>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};