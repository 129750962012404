import React from "react";
import TextField from "@material-ui/core/TextField";
import {majors, MAJORS_CEDAR, MAJORS_UVT, STATUSES, statuses} from "../../CommonUtilities/UtilityFunctions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function STUDENTS_LIGHT_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: "Nombre",
            field: "name",
            render: rowData =>
                <Button color="secondary" onClick={() => props.history.push(`/students/${rowData.studentUuid}`)}
                        component={Link}>
                    {rowData.name}
                </Button>,
            editComponent: props => (
                <TextField
                    value={props.value}
                    fullWidth
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: "Matrícula",
            field: "studentId",
        },
        {
            title: "Banco",
            field: "bankReference"
        },
        {
            title: "Grupo",
            field: "groupDisplay",
        },
        {
            title: "Status",
            field: "status",
            lookup: STATUSES,
        }
    ];
}
