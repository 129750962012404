import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StudentGradesTable from "./StudentGradesTable";
import StudentPaymentsTable from "./StudentPaymentsTable";
import StudentScholarshipsTable from "./StudentScholarshipsTable";
import {TabPanel} from "../Common/TabPanel";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const StudentDetailsTabsContainer = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label="Pagos" {...a11yProps(0)} />
                    <Tab label="Calificaciones" {...a11yProps(1)} />
                    <Tab label="Becas" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <StudentPaymentsTable studentUuid={props.studentUuid} student={props.student} grades={props.grades}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StudentGradesTable grades={props.grades} student={props.student}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <StudentScholarshipsTable scholarships={props.student.scholarships}/>
            </TabPanel>
        </div>
    );
};

export default StudentDetailsTabsContainer;