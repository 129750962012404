import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, {Fragment, useState} from "react";
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {API} from "aws-amplify";
import {Tooltip} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Typography from "@material-ui/core/Typography";
import {formatNumberCurrency} from "../CommonUtilities/UtilityFunctions";


const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 550
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    alignLeft: {
        float: "left"
    },
    alignRight: {
        float: "right"
    },
    textBox: {
        padding: "25px 0"
    },
    paymentTable: {
        width: "100%"
    }
}));

export const MSE_TESORERIA_PAYMENTS_CONCEPTS_MODAL = [
    {value: "CEDAR_UNIFORMES", description: "CEDAR Uniformes", price: 300},
    {value: "CEDAR_TINES", description: "CEDAR Tines", price: 60},
    {value: "CEDAR_LIBRETA", description: "CEDAR Libreta", price: 50},
    {value: "CEDAR_INTERSEMESTRAL", description: "CEDAR Intersemestral", price: 550},
    {value: "CEDAR_INTRASEMESTRAL", description: "CEDAR Intrasemestral", price: 550},
    {value: "CEDAR_REINSCRIPCION", description: "CEDAR Reinscripción", price: 1800},
    {value: "CEDAR_BAJA_DEFINITIVA", description: "CEDAR Baja Definitiva", price: 1500},
    {value: "CEDAR_CONSTANCIA_PARCIAL", description: "CEDAR_ Constancia Parcial", price: 1500},
    {value: "CEDAR_CONSTANCIA_SIMPLE", description: "CEDAR Constancia Simple", price: 50},
    {value: "CEDAR_CONSTANCIA_PASAPORTE", description: "CEDAR Constancia Pasaporte", price: 80},
    {value: "CEDAR_CONSTANCIA_CALIFICACIONES", description: "CEDAR Constancia con Calificaciones", price: 100},
    {value: "CEDAR_SECU_LIBROS", description: "CEDAR_SECU_LIBROS", price: 1800},
    {value: "CEDAR_SECU_LIBROS_PARCIAL1", description: "CEDAR_SECU_LIBROS_PARCIAL1", price: 1800},
    {value: "CEDAR_SECU_LIBROS_PARCIAL2", description: "CEDAR_SECU_LIBROS_PARCIAL2", price: 1800},
    {value: "CEDAR_SECU_LIBROS_PARCIAL3", description: "CEDAR_SECU_LIBROS_PARCIAL3", price: 1800},
    {value: "CEDAR_SECU_LIBROS_TOTAL", description: "CEDAR_SECU_LIBROS_TOTAL", price: 1800},
    {value: "CEDAR_PREPA_LIBROS", description: "CEDAR_PREPA_LIBROS", price: 1800},
    {value: "CEDAR_PREPA_LIBROS_PARCIAL1", description: "CEDAR_PREPA_LIBROS_PARCIAL1", price: 1800},
    {value: "CEDAR_PREPA_LIBROS_PARCIAL2", description: "CEDAR_PREPA_LIBROS_PARCIAL2", price: 1800},
    {value: "CEDAR_PREPA_LIBROS_PARCIAL3", description: "CEDAR_PREPA_LIBROS_PARCIAL3", price: 1800},
    {value: "CEDAR_PREPA_LIBROS_TOTAL", description: "CEDAR_PREPA_LIBROS_TOTAL", price: 1800},
    {value: "CEDAR_SECU_INSCRIPCION", description: "CEDAR_SECU_INSCRIPCION", price: 1800},
    {value: "CEDAR_PREPA_INSCRIPCION", description: "CEDAR_PREPA_INSCRIPCION", price: 1800},
    {value: "CEDAR_SECU_REINSCRIPCION", description: "CEDAR_SECU_REINSCRIPCION", price: 1800},
    {value: "CEDAR_PREPA_REINSCRIPCION", description: "CEDAR_PREPA_REINSCRIPCION", price: 1800},
    {value: "CEDAR_SECU_CUOTA_ANUAL", description: "CEDAR_SECU_CUOTA_ANUAL", price: 1800},
    {value: "CEDAR_PREPA_CUOTRA_ANUAL", description: "CEDAR_PREPA_CUOTRA_ANUAL", price: 1800},
    {value: "CEDAR_SECU_COLEGIATURA_10", description: "CEDAR_SECU_COLEGIATURA_10", price: 1800},
    {value: "CEDAR_PREPA_COLEGIATURA_10", description: "CEDAR_PREPA_COLEGIATURA_10", price: 2280},
    {value: "CEDAR_SECU_COLEGIATURA_12", description: "CEDAR_SECU_COLEGIATURA_12", price: 1500},
    {value: "CEDAR_PREPA_COLEGIATURA_12", description: "CEDAR_PREPA_COLEGIATURA_12", price: 1900},
    {value: "CEDAR_SECU_GASTOS FINALES", description: "CEDAR_SECU_GASTOS FINALES", price: 2000},
    {value: "CEDAR_PREPA_GASTOS FINALES", description: "CEDAR_PREPA_GASTOS FINALES", price: 3000},
    {value: "UVT_COLEGIATURA", description: "UVT Colegiatura", price: 1400},
    {value: "UVT_INSCRIPCION", description: "UVT Inscripción", price: 1000},
    {value: "UVT_REINSCRIPCION", description: "UVT Reinscripción", price: 1400},
    {value: "UVT_EXTRAORDINARIO", description: "UVT Examen Extraordinario", price: 500},
    {value: "UVT_CONSTANCIA_SIMPLE", description: "UVT Constancia Simple", price: 120},
    {value: "UVT_CONSTANCIA_CALIFICACIONES", description: "UVT Constancia Calificaciones", price: 200},
    {value: "UVT_TERMINO_ESTUDIOS", description: "UVT Término de Estudios", price: 500},
    {value: "UVT_EXAM_ORDINARIO", description: "UVT Examen Ordinario", price: 500},
    {value: "UVT_CERTIFICADO_PARCIAL", description: "UVT Certificado Parcial", price: 1500},
    {value: "UVT_CERTIFICADO_PARCIAL_IMPO", description: "UVT Certificado Parcial Impo", price: 2500},
    {value: "UVT_CERTIFICADO_TOTAL", description: "UVT Certificado Total", price: 1800},
    {value: "UVT_KARDEX", description: "UVT Kardex", price: 50},
    {value: "UVT_RECURSAMIENTO", description: "UVT Recursamiento", price: 800},
    {value: "UVT_EXAM_RECUPERACION", description: "UVT Examen de Recuperación", price: 300},
    {value: "UVT_REPOS_TITULO", description: "UVT Reposición del Título", price: 2500},
    {value: "UVT_EQUIVALENCIA", description: "UVT Equivalencia", price: 1500},
    {value: "UVT_BAJA_DEFINITIVA", description: "UVT Baja Definitiva", price: 1200},
    {value: "UVT_MATERIA_EQUIPARADA", description: "UVT Materia Equiparada", price: 100},
    {value: "UVT_PASE_VEHICULAR", description: "UVT Pase Vehicular", price: 50},
    {value: "UVT_LIBRETA", description: "UVT Libreta", price: 50},
    {value: "UVT_CARPETA_3", description: "UVT Carpeta 3''", price: 80},
    {value: "UVT_CARPETA_5", description: "UVT Carpeta 5''", price: 150},
    {value: "UVT_TITULACIONAL", description: "UVT_TITULACIONAL", price: 16000},
    {value: "UVT_TITULACIONAL_PARCIAL1", description: "UVT_TITULACIONAL_PARCIAL1", price: 16000},
    {value: "UVT_TITULACIONAL_PARCIAL2", description: "UVT_TITULACIONAL_PARCIAL2", price: 16000},
    {value: "UVT_TITULACIONAL_PARCIAL3", description: "UVT_TITULACIONAL_PARCIAL3", price: 16000},
    {value: "UVT_TITULACIONAL_TOTAL", description: "UVT_TITULACIONAL_TOTAL", price: 16000},
    {value: "UVT_CEDULA", description: "UVT Cédula", price: 896},
    {value: "UVT_MOD_TESIS", description: "UVT Titulación Modalidad Tesis", price: 18000},
    {value: "UVT_MOD_DIPLOMADO", description: "UVT_MOD_DIPLOMADO", price: 16000},
    {value: "UVT_MOD_DIPLOMADO_PARCIAL1", description: "UVT_MOD_DIPLOMADO_PARCIAL1", price: 16000},
    {value: "UVT_MOD_DIPLOMADO_PARCIAL2", description: "UVT_MOD_DIPLOMADO_PARCIAL2", price: 16000},
    {value: "UVT_MOD_DIPLOMADO_PARCIAL3", description: "UVT_MOD_DIPLOMADO_PARCIAL3", price: 16000},
    {value: "UVT_MOD_DIPLOMADO_TOTAL", description: "UVT_MOD_DIPLOMADO_TOTAL", price: 16000},
    {value: "UVT_MOD_CURSO_PROFUND", description: "UVT Titulación Modalidad Curso Profundo", price: 12000},
    {value: "UVT_MOD_OTRAS", description: "UVT_MOD_OTRAS", price: 5000},
    {value: "UVT_MOD_OTRAS_PARCIAL1", description: "UVT_MOD_OTRAS_PARCIAL1", price: 5000},
    {value: "UVT_MOD_OTRAS_PARCIAL2", description: "UVT_MOD_OTRAS_PARCIAL2", price: 5000},
    {value: "UVT_MOD_OTRAS_PARCIAL3", description: "UVT_MOD_OTRAS_PARCIAL3", price: 5000},
    {value: "UVT_MOD_OTRAS_TOTAL", description: "UVT_MOD_OTRAS_TOTAL", price: 5000},
    {value: "UVT_INGLES", description: "UVT Inglés", price: 1200},
    {value: "UVT_INGLES_EXTRA", description: "UVT Inglés Extraordinario", price: 500},
    {value: "UVT_INGLES_COLOCACION", description: "UVT Inglés Examen Colocación", price: 500},
    {value: "UVT_ENF_INSCRIPCION", description: "UVT Enfermería Inscripción", price: 1800},
    {value: "UVT_ENF_REINSCRIPCION", description: "UVT Enfermería Reinscripción", price: 1800},
    {value: "UVT_ENF_EXTRAORDINARIO", description: "UVT Enfermería Extraordinario", price: 500},
    {value: "UVT_ENF_COLEGIATURA", description: "UVT Enfermería Colegiatura", price: 1400},
    {value: "UVT_ENF_NIVELACION", description: "UVT Enfermería Nivelación", price: 300},
    {value: "UVT_ENF_PRACTICAS", description: "UVT Enfermería Prácticas", price: 1200},
    {value: "UVT_ENF_SEGURO", description: "UVT Enfermería Seguro", price: 1200},
    {value: "UVT_ESC_SEGURO", description: "UVT Seguro Escolar", price: 200},
    {value: "UVT_MAESTRIA_COLEGIATURA", description: "UVT Maestría Colegiatura", price: 1500},
    {value: "UVT_MAESTRIA_INSCRIPCION", description: "UVT Maestría Inscripción", price: 1500},
    {value: "UVT_MAESTRIA_REINSCRIPCION", description: "UVT Maestría Re-inscripción", price: 1500},
    {value: "OTRO", description: "OTRO", price: 0},
];

export const MSE_TESORERIA_MONTH_PAYMENTS = [
    {value: "NO_APLICA", description: "NO APLICA"},
    {value: 0, description: "ENERO"},
    {value: 1, description: "FEBRERO"},
    {value: 2, description: "MARZO"},
    {value: 3, description: "ABRIL"},
    {value: 4, description: "MAYO"},
    {value: 5, description: "JUNIO"},
    {value: 6, description: "JULIO"},
    {value: 7, description: "AGOSTO"},
    {value: 8, description: "SEPTIEMBRE"},
    {value: 9, description: "OCTUBRE"},
    {value: 10, description: "NOVIEMBRE"},
    {value: 11, description: "DICIEMBRE"},
];

export const MSE_TESORERIA_PAYMENT_TYPES = [
    {value: "NO_APLICA", description: "No Aplica"},
    {value: "EFECTIVO", description: "Efectivo"},
    {value: "DEPOSITO", description: "Depósito"},
    {value: "TARJETA_CREDITO", description: "Tarjeta de Crédito"},
    {value: "TARJETA_CREDITO_06_MSI", description: "Tarjeta de Crédito 6 Meses sin Intereses"},
    {value: "TARJETA_CREDITO_12_MSI", description: "Tarjeta de Crédito 12 Meses sin Intereses"},
    {value: "TARJETA_DEBITO", description: "Tarjeta de Débito"},
    {value: "TRANSFERENCIA_BANCARIA", description: "Transferencia Bancaria"},
];

export const MSE_TESORERIA_BANKS = [
    {value: "NO_APLICA", description: "No Aplica"},
    {value: "HSBC", description: "HSBC"},
    {value: "SANTANDER", description: "SANTANDER"},
    {value: "BBVA", description: "BBVA"},
    {value: "BANAMEX", description: "BANAMEX"},
    {value: "BANCOPPEL", description: "BANCOPPEL"},
    {value: "BANORTE", description: "BANORTE"},
    {value: "BANCO AZTECA", description: "BANCO AZTECA"},
    {value: "AMERICAN EXPRESS", description: "AMERICAN EXPRESS"},
    {value: "COMPARTAMOS BANCO", description: "COMPARTAMOS BANCO"},
    {value: "OTRO", description: "OTRO"},
];

export const MSE_TESORERIA_YEAR_CONCEPT = [
    {value: "2017", description: "2017"},
    {value: "2018", description: "2018"},
    {value: "2019", description: "2019"},
    {value: "2020", description: "2020"},
    {value: "2021", description: "2021"},
    {value: "2022", description: "2022"},
    {value: "2023", description: "2023"},
    {value: "2024", description: "2024"},
    {value: "2025", description: "2025"},
    {value: "2026", description: "2026"},
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ModalNewPayment({student, setIsLoading, onPaymentAdded}) {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [payment, setPayment] = useState({});
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    // };

    const createPaymentApi = () => {
        return API.post("misServiciosEscolares", "/tesoreria/payments", {
            body: {
                ...payment,
                price: getPriceForCurrentConceptRaw(),
                total: getPriceForCurrentConceptRaw(),
                studentUuid: student.studentUuid,
                startingCycle: student.startingCycle,
                school: student.school,
                name: student.name,
                major: student.major || "",
            }
        });
    };

    const handleSubmit = async () => {
        clearPaymentAndCloseModal()
        setIsLoading(true);
        try {
            const createdPayment = await createPaymentApi()
            if (createdPayment) {
                onPaymentAdded(createdPayment);
            } else {
                alert("No se pudo generar el pago, por favor intente de nuevo");
            }
        } catch (e) {
            alert("No se pudo generar el pago, por favor intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    };

    const clearPaymentAndCloseModal = () => {
        setOpenModal(false);
        setPayment({});
    };

    function getPriceForCurrentConceptRaw() {
        if (!payment?.concept) {
            return -1;
        }
        const index = MSE_TESORERIA_PAYMENTS_CONCEPTS_MODAL.findIndex(o => o.value === payment.concept);
        return MSE_TESORERIA_PAYMENTS_CONCEPTS_MODAL[index].price;
    }

    function getPriceForCurrentConcept() {
        const priceForConcept = getPriceForCurrentConceptRaw();
        if (priceForConcept === -1) {
            return -1;
        }
        return formatNumberCurrency(priceForConcept);
    }

    function getBankCommissions() {
        // TODO: Make this configurable
        if (!payment.concept || !payment.bank) {
            return -1
        }
        if (!payment.paymentType?.includes("TARJETA")) {
            return formatNumberCurrency(0);
        }
        const price = getPriceForCurrentConceptRaw();
        if (payment.bank === "HSBC" || payment.bank == "SANTANDER") {
            return formatNumberCurrency(0);
        } else {
            return formatNumberCurrency(price * 0.02);
        }
    }

    function disablePaymentButton() {
        const temp = !(
            payment.concept
            && (payment.monthConcept || payment.monthConcept === 0)
            && payment.yearConcept
            && payment.moneyTotal
            && payment.paymentType
        );
        return temp;
    }

    return (
        <div className="ModalNewPayment">
            <Fragment>
                <Dialog open={openModal} onClose={clearPaymentAndCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Crear Pago</DialogTitle>
                    <DialogContent>

                        <FormControl className={classes.FormControl}>
                            <InputLabel id="mse-tesoreria-concept-type-input-label-id">
                                Concepto de Pago (requerido)
                            </InputLabel>
                            <Select
                                labelId="mse-tesoreria-concept-label-id"
                                id="mse-tesoreria-concept-id"
                                value={payment.concept}
                                defaultValue={[]}
                                onChange={e => setPayment({...payment, concept: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {MSE_TESORERIA_PAYMENTS_CONCEPTS_MODAL.map(o => {
                                    return <MenuItem value={o.value}>{o.description}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>


                        {/*<FormControl className={classes.FormControl}>*/}
                        {/*    <InputLabel id="demo-mutiple-name-label">Name</InputLabel>*/}
                        {/*    <Select*/}
                        {/*        labelId="demo-mutiple-name-label"*/}
                        {/*        id="demo-mutiple-name"*/}
                        {/*        multiple*/}
                        {/*        value={payment.concept}*/}
                        {/*        onChange={e => setPayment({...payment, concept: e.target.value})}*/}
                        {/*        input={<Input/>}*/}
                        {/*        MenuProps={MenuProps}*/}
                        {/*    >*/}
                        {/*        {MSE_TESORERIA_PAYMENTS_CONCEPTS_MODAL.map(o => {*/}
                        {/*            return <MenuItem value={o.value}>{o.description}</MenuItem>;*/}
                        {/*        })}*/}

                        {/*    </Select>*/}
                        {/*</FormControl>*/}

                        <FormControl className={classes.FormControl}>
                            <InputLabel id="mse-tesoreria-payment-type-input-label-id">
                                Métodos de Pago (requerido)
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue=""
                                value={payment.paymentType}
                                onChange={e => setPayment({...payment, paymentType: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {MSE_TESORERIA_PAYMENT_TYPES.map(o => {
                                    return <MenuItem value={o.value}>{o.description}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <InputLabel id="mse-tesoreria-banks-input-label-id">
                                Banco (requerido)
                            </InputLabel>
                            <Select
                                labelId="mse-tesoreria-banks-input-label-id"
                                id="mse-tesoreria-banks-input-label-id"
                                value={payment.bank}
                                onChange={e => setPayment({...payment, bank: e.target.value})}
                                required
                                defaultValue=""
                                className={classes.FormControl}
                            >
                                {MSE_TESORERIA_BANKS.map(o => {
                                    return <MenuItem value={o.value}>{o.description}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <InputLabel id="mse-tesoreria-month-concept-input-label-id">
                                Pago en cuenta del mes: (opcional)
                            </InputLabel>
                            <Select
                                labelId="mse-tesoreria-month-concept-input-label-id"
                                id="mse-tesoreria-month-concept-input-label-id"
                                value={payment.monthConcept}
                                defaultValue=""
                                onChange={e => setPayment({...payment, monthConcept: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {MSE_TESORERIA_MONTH_PAYMENTS.map(o => {
                                    return <MenuItem value={o.value}>{o.description}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <InputLabel id="mse-tesoreria-month-concept-input-label-id">
                                Pago en cuenta del año: (opcional)
                            </InputLabel>
                            <Select
                                labelId="mse-tesoreria-month-concept-input-label-id"
                                id="mse-tesoreria-month-concept-input-label-id"
                                value={payment.yearConcept}
                                defaultValue=""
                                onChange={e => setPayment({...payment, yearConcept: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {MSE_TESORERIA_YEAR_CONCEPT.map(o => {
                                    return <MenuItem value={o.value}>{o.description}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            id="money-total"
                            label="Total Dinero Recibido"
                            type="number"
                            value={payment.moneyTotal || ""}
                            onChange={e => setPayment({...payment, moneyTotal: e.target.value})}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            id="reference"
                            label="Referencia (opcional)"
                            value={payment.reference || ""}
                            onChange={e => setPayment({...payment, reference: e.target.value})}
                            className={classes.FormControl}
                        />
                        {payment.paymentType === "TRANSFERENCIA_BANCARIA"
                            ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Fecha en que se realizó la transferencia bancaria"
                                    value={selectedDate || new Date()}
                                    onChange={setSelectedDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            : <></>
                        }
                        <div className={classes.textBox}>
                            <Typography variant="h5" component="h2">
                                Información de Pago
                            </Typography>
                            <table className={classes.paymentTable}>
                                <tr>
                                    <td>Precio</td>
                                    <td>{
                                        payment.concept
                                            ? <>+{getPriceForCurrentConcept()}</>
                                            : <>No disponible</>
                                    }</td>
                                </tr>
                                <tr>
                                    <td>Comisión bancaria</td>
                                    <td>{
                                        getBankCommissions() === -1
                                            ? <>No Disponible</>
                                            : getBankCommissions()
                                    }</td>
                                </tr>
                                <tr>
                                    <td>Comisión por pago tardío</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>Beca</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>Voucher</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td>{
                                        payment.concept
                                            ? <b>+{getPriceForCurrentConcept()}</b>
                                            : <>No disponible</>
                                    }</td>
                                </tr>
                            </table>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={clearPaymentAndCloseModal}
                            color="primary"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            disabled={disablePaymentButton()}
                        >
                            Crear Pago
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
            <Fab color="primary" aria-label="add" className={classes.fab}>
                <Tooltip title="Crear Pago" aria-label="add">
                    <Fab color="primary" className={classes.fab}>
                        <AddIcon onClick={() => setOpenModal(true)}/>
                    </Fab>
                </Tooltip>
            </Fab>
        </div>
    );
};