import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PaymentsSummaryTable from "./PaymentsSummaryTable";
import {TabPanel} from "../Common/TabPanel";
import PaymentsSummaryConcept from "./PaymentsSummaryConcept";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const PaymentsSummaryDetailsTabsContainer = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label="Pagos del Día" {...a11yProps(0)} />
                    <Tab label="Resumen por Concepto" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <PaymentsSummaryTable
                    dayPayments={props.dayPayments}
                    history={props.history}
                    isLoading={props.isLoading}
                    setIsLoading={props.setIsLoading}
                    onOutcomeCreate={props.onOutcomeCreate}
                    updateDate={props.updateDate}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PaymentsSummaryConcept
                    dayPaymentsMetadata={props.dayPaymentsMetadata}
                />
            </TabPanel>
        </div>
    );
};

export default PaymentsSummaryDetailsTabsContainer;