import React, {useEffect, useState} from "react";
import "../Stylesheets/Home.css";
import {API, Auth} from "aws-amplify";
import MaterialTable from "material-table";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {API_BASE_PATH} from "../../components/CommonUtilities/UtilityFunctions";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {LOCALIZATION} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {STUDENTS_LIGHT_COLUMN_DEFINITION_PROPS} from "../../components/Material-TableCustomization/ColumnsDefinition/StudentsLightColumnDefinition";

export default function Home(props) {

    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadStudentsApi = () => {
        return API.get(API_BASE_PATH, "/students?details=TESORERIA");
    };

    const loadStudents = async () => {
        setIsLoading(true);
        try {
            const students = await loadStudentsApi();
            setStudents(students);
        } catch (e) {
            alert("No se pudo actualizar la lista de estudiantes. Por favor intente de nuevo.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeOnAlert = shouldPresentAlert => {
        setAlertStatus(shouldPresentAlert);
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadStudents();
    }, [props.isAuthenticated]);

    function renderStudents() {
        // TODO: Update localization and put "Filtrar estudiantes"
        return (
            <div className="Students">
                <MaterialTable
                    columns={STUDENTS_LIGHT_COLUMN_DEFINITION_PROPS(props)}
                    data={students}
                    title="Estudiantes Activos UVT"
                    isLoading={isLoading}
                    options={{
                        search: true,
                        filtering: true,
                        pageSize: 20,
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('estudiante', 'estudiantes')}
                />
            </div>
        );
    }


    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderStudents() : renderLander()}
        </div>
    );
};